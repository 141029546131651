import { useBreak } from "hooks/useBreak";
import { Label } from "./Overview.label";
import { _bootstrap } from "state/store.bootstrap";
import { getFocus, useAtom, useGet } from "state/jotai";
import { current_language, rotate_by_slider } from "state/store.global";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { MobileLabel } from "./MobileOverview.label";
import { _project } from "state/store.projects";

export const Overview = ({ index, rmode, status }) => {
  const labelOptions = getFocus(_bootstrap, "projectnames");
  const currentLanguage = useAtom(current_language)[0];
  const isRotate_Done = useGet(rotate_by_slider);
  const isDesktop = useBreak("md_up");

  const L = [
    // desktop labels position
    //desktop labels L1 is for etape1 label & L2 is for etape2 label;
    { L1: [130, 428], L2:[630, 428] , L3:[300,428] , L4:[465,428]  },
    // { L1: [800, 691], L2: [0, 289] },
    // { L1: [15, 200], L2: [1500, 600] },
    // { L1: [700, 195], L2: [900, 800] },
    // { L1: [1220, 300], L2: [100, 700] },
  ];

  const circle = [
    // desktop circle position
    { L1: [-400, 300], L2: [-400, 330] ,L3:[-380,300] , L4:[-390,350]  },
    // { L1: [-400, -57], L2: [500, 600] },
    // { L1: [900, 448], L2: [-600, -102] },
    // { L1: [0, 510], L2: [-138, -292] },
    // { L1: [-400, 300], L2: [900, -200] },
  ];

  const mobileL = [
    // circle position
    { L1: [80, 185], L2: [575, 175] ,L3:[250,170], L4:[420,170]},
    // { L1: [568, 655], L2: [257, 597] },
    // { L1: [467, 423], L2: [1188, 554] },
    // { L1: [650, 465], L2: [863, 644] },
    // { L1: [1055, 450], L2: [500, 562] },
  ];

  const mobileLine = [
    // how much line shoule be big from both axis
    { L1: [400, 8], L2: [500, 8] , L3:[400,8] , L4:[400,8] },
    // { L1: [900, 0], L2: [900, 0] },
    // { L1: [500, 0], L2: [600, 0] },
    // { L1: [500, 0], L2: [900, 0] },
    // { L1: [500, 0], L2: [-700, 0] },
  ];

  const mobileLineAngle = [
    // labels line rotation from the pin point (circle)
    { L1: -28, L2: -28 , L3 : -28 , L4 : -28},
    // { L1: 309, L2: 278 },
    // { L1: 285, L2: 266 },
    // { L1: 285, L2: 312 },
    // { L1: 285, L2: 78 },
  ];

  const mobileLabelAxis = [
    // labels position (i.e top & left)
    { L1: [235, 30], L2: [245, 100] , L3:[250, 50],  L4:[250, 70]},
    // { L1: [-417, 254], L2: [-417, 15] },
    // { L1: [-300, 42], L2: [-300, -45] },
    // { L1: [-300, 40], L2: [-400, 270] },
    // { L1: [-300, 40], L2: [-390, -90] },
  ];

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.1, ease: "linear" },
  };

  return (
    <AnimatePresence>
      {status === "DONE" && isRotate_Done && (
        <>
          {isDesktop
            ? labelOptions?.[currentLanguage].map((label, i) => {
                if (i) {
                  // labels will genrate according to different properties
                  return (
                    <motion.div {...animation} key={i}>
                      <Label
                        c={L?.[index]?.[`L${i}`]}
                        sc={1}
                        d={40}
                        l={0}
                        r={circle?.[index]?.[`L${i}`]}
                        {...{ rmode }}
                        labelsInfo={label}
                      />
                    </motion.div>
                  );
                }
              })
            : labelOptions?.[currentLanguage].map((label, i) => {
                if (i) {
                  // labels will genrate according to different properties
                  return (
                    <motion.div {...animation} key={i}>
                      <MobileLabel
                        c={mobileL?.[index]?.[`L${i}`]}
                        sc={1}
                        d={40}
                        l={0}
                        r={circle?.[index]?.[`L${i}`]}
                        {...{ rmode }}
                        labelsInfo={label}
                        line={mobileLine?.[index]?.[`L${i}`]}
                        angle={mobileLineAngle?.[index]?.[`L${i}`]}
                        mobileLabelAxis={mobileLabelAxis?.[index]?.[`L${i}`]}
                      />
                    </motion.div>
                  );
                }
              })}
        </>
      )}
    </AnimatePresence>
  );
};
